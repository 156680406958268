<template>
  <div ref="boxRef" class="enjoy-box">
    <div class="top-area">
      <div class="sticky-box">
        <div ref="topRef" class="content-area">
          <slot name="top" />
        </div>
      </div>
    </div>
    <div ref="centerBoxRef" class="center-area">
      <div class="sticky-box">
        <div ref="centerRef" class="content-area">
          <slot :ani-params="aniParams" />
        </div>
      </div>
    </div>
    <div class="bottom-area">
      <div class="sticky-box">
        <div ref="bottomRef" class="content-area">
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ResizeSensor } from "css-element-queries"
import { EmitName } from "~/constant/eventBus"
import { Breakpoints } from "~/constant/pages/newHome"
const topRef = ref()
const centerRef = ref()
const bottomRef = ref()
const stickyDuring = ref(0)
const during = ref(0)

const topHeight = ref<string | number>("auto")

const getTopHeight = () => {
  if (!topRef.value) {
    topHeight.value = 0
    return
  }
  topHeight.value = topRef.value.offsetHeight
}

const centerHeight = ref<string | number>("auto")

const getCenterHeight = () => {
  if (!centerRef.value) {
    centerHeight.value = 0
    return
  }
  centerRef.value
  centerHeight.value = centerRef.value.offsetHeight
}

const bottomHeight = ref<string | number>("auto")

const getBottomHeight = () => {
  if (!bottomRef.value) {
    bottomHeight.value = 0
    return
  }
  bottomHeight.value = bottomRef.value.offsetHeight
}

const setStickyDuring = () => {
  if (!window) {
    return
  }
  stickyDuring.value = (window.innerHeight - centerHeight.value) / 2
}

const centerBoxRef = ref()

const aniParams = reactive({
  start: 0,
  end: 0
})

const boxRef = ref()

const getInitData = () => {
  aniParams.start = boxRef.value?.offsetTop + topRef.value?.offsetHeight
  aniParams.end = aniParams.start + during.value - stickyDuring.value - 200
}

const breakPointList = useBreakpoints(Breakpoints) as any

const getResizeSensor = () => {
  nextTick(async () => {
    if (breakPointList.$1920.value) {
      during.value = 800
    } else if (breakPointList.$1400.value) {
      during.value = 800
    } else if (breakPointList.$900.value) {
      during.value = 0
    } else if (breakPointList.$600.value) {
      during.value = 0
    } else {
      during.value = 0
    }
    // await delay(50)
    getTopHeight()
    getCenterHeight()
    getBottomHeight()
    setStickyDuring()
    getInitData()
  })
}

onMounted(async () => {
  const body = document.getElementsByTagName("body")[0]
  new ResizeSensor(body, () => {
    getResizeSensor()
  })
})
</script>

<style lang="scss" scoped src="./index.scss" />

<style lang="scss" scoped>
.enjoy-box {
  --during: calc(v-bind(during) * 1px);
  --sticky-during: calc(v-bind(stickyDuring) * 1px);
  --topHeight: calc(v-bind(topHeight) * 1px);
  --centerHeight: calc(v-bind(centerHeight) * 1px);
  --bottomHeight: calc(v-bind(bottomHeight) * 1px);
}
</style>
